import { useState } from "react";

function Square({isWinSquare, img_src, value, onSquareClick, i}) {

    function ImageTag() {
      const opacity_style = isWinSquare ? 1 : 0.5;
      return (
        <img src={img_src} alt={value} style={{opacity: opacity_style}}></img>
      )
    }
  
    let image_tag;
    if (value) {image_tag = <ImageTag />};
  
    return (
    <button className="square" onClick={onSquareClick}>
      {image_tag}         
    </button>
    );
} 
  
function Board({squares, onClick, winLine}) {
    function renderSquare(i) {
        return (
        <Square 
            value={squares[i]}
            i = {i}
            img_src={"./" + squares[i] + ".png"}
            onSquareClick={() => onClick(i)}
            isWinSquare={winLine && winLine.includes(i)}
        />
        );
    }
    
    return (
      <div>
        <div className="board-row">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
        </div>
        <div className="board-row">
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
        </div>
        <div className="board-row">
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
      </div>
    )
}
  
export default function Cross() {
const [history, setHistory] = useState([Array(9).fill(null)]);
const [winLine, setWinLine] = useState(null);
const xIsNext = (history.length-1) % 2 === 0;
const status = winLine ? 'Выиграл ' + (!xIsNext ? 'Заяц' : 'Волк') + '!' : 'Следующий: ' + (xIsNext ? 'Заяц' : 'Волк');

function calculateWinner(squares) {
    const lines = [[0,1,2], [3,4,5], [6,7,8], [0,3,6], [1,4,7], [2,5,8], [0,4,8], [2,4,6]];
    let res = [];
    console.log('Выясняем, есть ли победитель...');
    for (let i = 0; i < lines.length; i++) {
        let [a,b,c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        res = [a,b,c];
            console.log('Есть победитель! Строка:'+res);
            return res;
        }
    }
        console.log('...победитель не определен.');
        return false;
}

function handleClick(i) {
    const currentSquares = history[history.length-1].slice();
    if (!currentSquares[i] && !winLine) {
        console.log('клик '+i); 
        currentSquares[i] = xIsNext ? 'Заяц' : 'Волк';
        setWinLine(calculateWinner(currentSquares));
        setHistory(history.concat([currentSquares]))
    }
    
    return winLine;
}

function jumpTo(step) {
    // setStepNumber(step);
    // // setXIsNext (step % 2 === 0);
    // // setIsWinner (null);
    setWinLine(null);
    setHistory(history.slice(0,step+1));
    
    console.log('step = ' + step);
}

const moves = history.slice(0,-1).map((squares, move) => {
    let description;
    if (move > 0) {
      description = 'ход №' + move;
    } else {
      description = 'К началу';
    }
    return (
      <li key={move}>
        <button className="control" onClick={() => jumpTo(move)}>{description}</button>
      </li>
    );
}
)


return (
<div className="game">
    <div className="game-board">
        <Board 
            squares={history[history.length-1]}
            onClick={handleClick}
            winLine={winLine}
        />
    </div>
    <div className="game-info">
        <div id="status">{status}</div>
        <span>текущий ход № {history.length-1}</span>
        <ol>
            {moves}
        </ol>
    </div>
</div>
)
}

