// import React from 'react';
// import { useState } from 'react';
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import './index.css';
import Cross from "./cross";



// ========================================

const cross = createRoot(document.getElementById("cross"));
cross.render(
    <StrictMode>
        <Cross />
    </StrictMode>
);
